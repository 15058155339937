import axios, { AxiosInstance } from "axios";

const api: AxiosInstance = axios.create({
  // baseURL: "https://api02.heymandi.com/api",
  baseURL: "https://tagging.heymandi.com/api",
  // baseURL: "http://52.77.254.9:8000/api",
  timeout: 30000, // 30 secs
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "TOKEN": window.location.pathname.substring(1)
  },
});

export async function apiGetNftCollections(cursor: string): Promise<any> {
  const response = await api.get(`/nft/collection?cursor={cursor}&limit=10`);
  const result = response.data;
  return result;
}

export async function apiBindWallet(wallet_address: string): Promise<any> {
  const response = await api.post(`/nft/bind/`, { wallet_address });
  const result = response.data;
  return result;
}

export async function apiUnbindWallet(): Promise<any> {
  const response = await api.post(`/nft/unbind/`);
  const result = response.data;
  return result;
}

export async function apiSelectNFT(contract_address: string, token_id: string, message: string, signature: string): Promise<any> {
  const response = await api.post(`/nft/select/`, { contract_address, token_id, message, signature });
  const result = response.data;
  return result;
}

// export async function apiGetAccountTransactions(
//   address: string,
//   chainId: number,
// ): Promise<IParsedTx[]> {
//   const response = await api.get(`/account-transactions?address=${address}&chainId=${chainId}`);
//   const { result } = response.data;
//   return result;
// }

// export const apiGetAccountNonce = async (address: string, chainId: number): Promise<string> => {
//   const response = await api.get(`/account-nonce?address=${address}&chainId=${chainId}`);
//   const { result } = response.data;
//   return result;
// };

// export const apiGetGasPrices = async (): Promise<IGasPrices> => {
//   const response = await api.get(`/gas-prices`);
//   const { result } = response.data;
//   return result;
// };
