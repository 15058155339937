import * as React from "react";
import styled from "styled-components";
import WalletConnect from "@walletconnect/client";
import QRCodeModal from "@walletconnect/qrcode-modal";
import { convertUtf8ToHex } from "@walletconnect/utils";
import { IInternalEvent } from "@walletconnect/types";
import Button from "./components/Button";
import Column from "./components/Column";
import Wrapper from "./components/Wrapper";
import Header from "./components/Header";
// import Loader from "./components/Loader";
import NFTView from "./components/NFTView";
import { fonts } from "./styles";
import { apiBindWallet, apiGetNftCollections, apiUnbindWallet, apiSelectNFT } from "./helpers/hmd_api";
// import {
//   verifySignature,
//   hashTypedDataMessage,
//   hashMessage,
// } from "./helpers/utilities";
import { IAssetData } from "./helpers/types";
// import AccountAssets from "./components/AccountAssets";
// import { eip712 } from "./helpers/eip712";
// import { Address } from "ethereumjs-util";



const SLayout = styled.div`
  position: relative;
  width: 100%;
  /* height: 100%; */
  min-height: 100vh;
  text-align: center;
`;

const SContent = styled(Wrapper as any)`
  width: 100%;
  height: 100%;
  padding: 0 16px;
`;

const SLanding = styled(Column as any)`
  height: 600px;
`;

const SButtonContainer = styled(Column as any)`
  width: 250px;
  margin: 50px 0;
`;

const SConnectButton = styled(Button as any)`
  border-radius: 8px;
  font-size: ${fonts.size.medium};
  height: 44px;
  width: 100%;
  margin: 12px 0;
`;



// @ts-ignore
const SBalances = styled(SLanding as any)`
  height: 100%;
  & h3 {
    padding-top: 30px;
  }
`;


const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
`
const SNFTView = styled(Button as any)`
  background-color: #fff;
`

// const STestButtonContainer = styled.div`
//   width: 100%;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   flex-wrap: wrap;
// `;

// const STestButton = styled(Button as any)`
//   border-radius: 8px;
//   font-size: ${fonts.size.medium};
//   height: 44px;
//   width: 100%;
//   max-width: 175px;
//   margin: 12px;
// `;

interface IAppState {
  connector: WalletConnect | null;
  fetching: boolean;
  connected: boolean;
  chainId: number;
  pendingRequest: boolean;
  uri: string;
  accounts: string[];
  address: string;
  result: any | null;
  assets: IAssetData[];
  userToken: string;
  nfts: any | null;
  total: number;
  signature: string;
  initialized: boolean;
}


const INITIAL_STATE: IAppState = {
  connector: null,
  fetching: false,
  connected: false,
  chainId: 1,
  pendingRequest: false,
  uri: "",
  accounts: [],
  address: "",
  result: null,
  assets: [],
  userToken: window.location.pathname.substring(1),
  nfts: [],
  total: 0,
  signature: "",
  initialized: false,
};



class App extends React.Component<any, any> {
  public state: IAppState = {
    ...INITIAL_STATE,
  };

  componentDidMount(): void {
    if(this.isConnected()){
      this.connect();
    }else{
      this.setState({initialized: true});
    }
  }

  componentWillUnmount(): void {
    console.log('componentWillUnmount');
    (window as any).closeWebview = false;
  }

  public isConnected = () => {
    // bridge url
    const bridge = "https://bridge.walletconnect.org";

    // create new connector
    const connector = new WalletConnect({ bridge, qrcodeModal: QRCodeModal });

    return connector.connected;
  }

  public connect = async () => {
    // bridge url
    const bridge = "https://bridge.walletconnect.org";

    // create new connector
    const connector = new WalletConnect({ bridge, qrcodeModal: QRCodeModal });

    console.log(this.state.userToken);

    await this.setState({ connector });

    // check if already connected
    if (!connector.connected) {
      // create new session
      await connector.createSession();
    }

    // subscribe to events
    await this.subscribeToEvents();
    this.setState({initialized: true});
  };
  public subscribeToEvents = async () => {
    const { connector } = this.state;

    if (!connector) {
      return;
    }

    connector.on("session_update", async (error, payload) => {
      console.log(`connector.on("session_update")`);

      if (error) {
        throw error;
      }

      const { chainId, accounts } = payload.params[0];
      this.onSessionUpdate(accounts, chainId);
    });

    connector.on("connect", (error, payload) => {
      console.log(`connector.on("connect")`);

      if (error) {
        throw error;
      }

      this.onConnect(payload);
    });

    connector.on("disconnect", (error, payload) => {
      console.log(`connector.on("disconnect")`);
      if (error) {
        throw error;
      }

      this.onDisconnect();
    });

    if (connector.connected) {
      const { chainId, accounts } = connector;
      console.log(connector);
      const address = accounts[0];
      const result = await apiBindWallet(address);
      console.log(result);
      const nfts = result.data;
      const total = result.total;
      const cursor = result.cursor;

      this.setState({
        connected: true,
        chainId,
        accounts,
        address,
        nfts,
        total,
        cursor,
      });
      this.onSessionUpdate(accounts, chainId);
    }

    this.setState({ connector });

    
  };

  public killSession = async () => {
    const { connector } = this.state;
    if (connector) {
      connector.killSession();
    }
    this.resetApp();
  };

  public resetApp = async () => {
    await this.setState({ ...INITIAL_STATE });
  };

  public onConnect = async (payload: IInternalEvent) => {
    const { chainId, accounts } = payload.params[0];
    const address = accounts[0];
    // (window as any).wallet_address = address;
    await this.setState({
      connected: true,
      chainId,
      accounts,
      address,
    });
    // this.getAccountAssets();
    this.getNftColletions();
  };

  public onDisconnect = async () => {
    await apiUnbindWallet();
    this.resetApp();
  };

  public onSessionUpdate = async (accounts: string[], chainId: number) => {
    const address = accounts[0];
    // (window as any).wallet_address = address;
    await this.setState({ chainId, accounts, address });
    // await this.getAccountAssets();
    this.getNftColletions();
  };

  public getAccountAssets = async () => {
    const { address } = this.state;
    this.setState({ fetching: true });
    try {

      await this.setState({ fetching: false, address });
    } catch (error) {
      console.error(error);
      await this.setState({ fetching: false });
    }
  };

  public getNftColletions = async () => {

    await this.setState({ fetching: true });
    
    try {
      // get nft collections
      const result = await apiGetNftCollections("")
      console.log(result);
      const nfts = result.data;
      const total = result.total;

      await this.setState({ fetching: false, nfts, total });
    } catch (error) {
      console.error(error);
      await this.setState({ fetching: false });
    }
  }

  // public toggleModal = () => this.setState({ showModal: !this.state.showModal });

  // public testPersonalSignMessage =  () => {
  //   const { connector, address, chainId } = this.state;

  //   if (!connector) {
  //     return;
  //   }

  //   // test message
  //   const message = `Allow Heymandi to show your NFT?`;

  //   // encode message (hex)
  //   const hexMsg = convertUtf8ToHex(message);

  //   // eth_sign params
  //   const msgParams = [hexMsg, address];

  //   try {
  //     // open modal
  //     this.toggleModal();

  //     // toggle pending request indicator
  //     this.setState({ pendingRequest: true });

  //     // send message
  //     const result = await connector.signPersonalMessage(msgParams);

  //     // verify signature
  //     const hash = hashMessage(message);
  //     const valid = await verifySignature(address, result, hash, chainId);

  //     // format displayed result
  //     const formattedResult = {
  //       method: "personal_sign",
  //       address,
  //       valid,
  //       result,
  //     };

  //     // display result
  //     this.setState({
  //       connector,
  //       pendingRequest: false,
  //       result: formattedResult || null,
  //     });
  //   } catch (error) {
  //     console.error(error);
  //     this.setState({ connector, pendingRequest: false, result: null });
  //   }
  // };

  public selectNFT = async (nft: any) => {
    const { connector, address } = this.state;

    console.log('select NFT:' + nft.name);
  
    if (!connector) {
      return;
    }

    // test message
    const message = `Allow Heymandi to show your NFT?`;

    // encode message (hex)
    const hexMsg = convertUtf8ToHex(message);

    // eth_sign params
    const msgParams = [hexMsg, address];

    console.log(msgParams)

    try {
      // toggle pending request indicator
      this.setState({ pendingRequest: true });

      // send message
      const signature = await connector.signPersonalMessage(msgParams);

      console.log(signature)

      const result = await apiSelectNFT(nft.contract_address, nft.token_id, message, signature);
      console.log(result)

      this.setState({
        connector,
        pendingRequest: false,
        signature,
      });

      (window as any).closeWebview = true;
    } catch (error) {
      console.log(error)
      console.error(error);
      this.setState({ connector, pendingRequest: false, signature: null });
    }
  }

  public render = () => {
    const {
      address,
      connected,
      chainId,
      fetching,
      // pendingRequest,
      // signature,
      nfts,
      initialized,
    } = this.state;
    return (
      <SLayout>
        <Column maxWidth={1000} spanHeight>
          <Header
            connected={connected}
            address={address}
            chainId={chainId}
            killSession={this.killSession}
          />
          <SContent>
            {!address && initialized ? (
              <SLanding center>
                <SButtonContainer>
                  <SConnectButton left onClick={this.connect} fetching={fetching}>
                    {"Connect to Wallet"}
                  </SConnectButton>
                </SButtonContainer>
              </SLanding>
            ) : (
              <SBalances>
                <Column center>
                  <GridContainer>
                    {nfts.map((nft: any) => (
                      <SNFTView key={nft.name} onClick={ (_: any) => this.selectNFT(nft)}>
                        <NFTView key={nft.name} nft={nft} />
                      </SNFTView>
                    ))}
                  </GridContainer>
                </Column>
              </SBalances>
            )}
          </SContent>
        </Column>
      </SLayout>
    );
  };
}

export default App;
