import * as React from "react";
import styled from "styled-components";

interface INFT {
  contractName: string;
  contractAddress: string;
  tokenID: string;
  name: string;
  thumbnail_url: string;
  content_url: string;
  attributes: any;
}

interface IProps {
  nft: INFT;
}

const NFTCard = styled.div`
  padding: 0;
`

const NFTCardImage = styled.img`
  display: block;
  width: 100%;
  height: auto;
  border-radius: 8px;
`

const NFTName = styled.p`
  font-size: 12px;
  color: #666;
  font-weight: bold;
  margin: 5px 0 0 0;
`

const NFTView: React.FC<IProps> = ({ nft }) => {
  return (
    <NFTCard className="card">
      <NFTCardImage src={nft.thumbnail_url} alt={nft.name} />
      <NFTName>{nft.name}</NFTName>
    </NFTCard>
  );
};

export default NFTView;